import {
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Category from "../Category/Category";
import { useToken } from "../TokenContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  }
}));

export default function Categories() {
  const [categories, setCategories] = useState([]);

  const token = useToken();

  useEffect(() => {
    fetch("api/financeapi/GetFullExpenseCategories", {
      headers: {
        "API-TOKEN": token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setCategories(json);
      });
  }, [token]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {categories.map((category) => (
        <Category category={category} />
      ))}
    </div>
  );
}
