import React, { useState, useContext } from "react";
import queryString from "query-string";

const TokenContext = React.createContext();

export function useToken() {
  return useContext(TokenContext);
}

export default function TokenProvider({ children }) {
  const [token, setToken] = useState(null);

  if (!token) {
    let queryParams = queryString.parse(document.location.search);
    setToken(queryParams.token);
  }

  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
}
