import React, { useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Expense from "../Expense/Expense";
import Expenses from "../Expenses/Expenses";
import Income from "../Income/Income";
import TokenProvider from "../TokenContext";
import Categories from "../Categories/Categories";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LocalOfferSharpIcon from "@material-ui/icons/LocalOfferSharp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Typography>{children}</Typography>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function App() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const expenseRef = useRef();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsMenuOpen(open);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCategoriesClick = () => {
    setIsMenuOpen(false);
    setSelectedTab(3);
  };

  const handleEditExpense = (id) => {
    setIsMenuOpen(false);
    setSelectedTab(0);
    expenseRef.current.edit(id);
  };

  return (
    <TokenProvider>
      <AppBar position="static" color="default">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Väljaminek" {...a11yProps(0)} />
            <Tab label="Sissetulek" {...a11yProps(1)} />
            <Tab label="Andmed" {...a11yProps(2)} />
            {/* <Tab label="Kategooriad" {...a11yProps(3)} /> */}
          </Tabs>
        </Toolbar>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Expense ref={expenseRef} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Income />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Expenses onEdit={handleEditExpense} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <Categories />
      </TabPanel>
      <SwipeableDrawer
        anchor="left"
        open={isMenuOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <List>
          <ListItem button onClick={handleCategoriesClick}>
            <ListItemIcon>
              <LocalOfferSharpIcon />
            </ListItemIcon>
            <ListItemText primary="Kategooriad" />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </TokenProvider>
  );
}

export default App;
