import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Box, makeStyles } from "@material-ui/core";
import { useToken } from "../TokenContext";

const useStyles = makeStyles(() => ({
  containerGrid: {
    marginBottom: "0 !important",
  },
}));

export default function Income() {
  const [sum, setSum] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(null);
  const [alertState, setAlertState] = useState({});
  const token = useToken();

  const handleAddAndContinueIncome = () => {
    addIncome(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    addIncome(true);
  };

  const addIncome = (clearForm) => {
    if (clearForm) {
      setSum("");
      setDescription("");
      setDate(null);
    }

    fetch("api/financeapi/PostIncome", {
      method: "POST",
      headers: {
        "API-TOKEN": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Description: description,
        Sum: sum,
        Date: date,
      }),
    }).then((response) => {
      if (response.status === 200) {
        setAlertState({
          text: "Lisatud!",
          severity: "success",
          isVisible: true,
        });
      } else {
        setAlertState({
          text: "Viga lisamisel!",
          severity: "error",
          isVisible: true,
        });
      }

      setTimeout(() => {
        setAlertState({ ...alertState, isVisible: false });
      }, 3000);
    });
  };

  const classes = useStyles();

  return (
    <Box p={3}>
      <form onSubmit={handleFormSubmit}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing="1"
          className={classes.containerGrid}
        >
          <Grid item xs={12}>
            <TextField
              label="Summa"
              type="number"
              variant="outlined"
              fullWidth
              value={sum}
              onChange={(e) => setSum(parseFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-required"
              label="Kirjeldus"
              variant="outlined"
              fullWidth
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Kuupäev"
                format="dd/MM/yyyy"
                mask="__/__/____"
                value={date}
                onChange={(date) => setDate(date)}
                fullWidth
                inputVariant="outlined"
                autoOk="true"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.containerGrid}
            >
              <ButtonGroup color="primary">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleAddAndContinueIncome}
                >
                  Lisa ja jätka
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Lisa
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {alertState.isVisible ? (
              <Alert
                severity={alertState.severity}
                color={alertState.severity}
                variant="standard"
              >
                {alertState.text}
              </Alert>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
