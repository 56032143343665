import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    marginBottom: "0 !important",
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export default function Category({category}) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        wrap="nowrap"
        spacing={1}
        className={classes.containerGrid}
      >
        <Grid item>
          <Avatar>{category.symbols}</Avatar>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{category.name}</Typography>
          <Typography variant="body2">{category.description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
