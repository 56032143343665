import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Chip, Grid, Switch } from "@material-ui/core";
import "./Expenses.css";
import { useToken } from "../TokenContext";

export default function Expenses({onEdit}) {
  const token = useToken();
  const tableRef = React.createRef();

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const getData = (query) => {
    return new Promise((resolve) => {
      let url = "api/financeapi/FinanceGridData?";
      fetch(url, {
        method: "POST",
        headers: {
          "API-TOKEN": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          PerPage: query.pageSize,
          CurrentPage: query.page + 1,
          SearchPhrase: query.search,
          DataType: viewMode ? "expenses" : "incomes",
          Sort: (() => {
            let sort = {};
            if (query.orderDirection === "") return sort;

            sort[query.orderBy.field] = query.orderDirection;

            return sort;
          })(),
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          resolve({
            data: result.rows,
            page: result.currentPage - 1,
            totalCount: result.total,
          });
        });
    });
  };

  const deleteRow = (event, rowData) => {
    let result = window.confirm("Kustuta?");

    if (!result) return;

    let url =
      `api/financeapi/Remove${viewMode ? "Expense" : "Income"}?id=` +
      rowData.id;
    fetch(url, {
      method: "DELETE",
      headers: {
        "API-TOKEN": token,
      },
    }).then(() => {
      tableRef.current && tableRef.current.onQueryChange();
    });
  };

  const editRow = (event, rowData) => {
    onEdit(rowData.id);
  };

  const searchChange = (x) => {
    console.log("search change:");
    console.log(x);
  };

  const onViewModeChange = () => {
    setViewMode(!viewMode);

    tableRef.current && tableRef.current.onQueryChange();
  };

  // true = expenses, false = incomes
  const [viewMode, setViewMode] = useState(true);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing="1"
    >
      <Grid item xs={12}>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            { title: "Kuupäev", field: "date", type: "date" },
            {
              title: "Kategooria",
              field: "category",
              cellStyle: { "white-space": "nowrap" },
            },
            {
              title: "Summa €",
              field: "sum",
              headerStyle: { "white-space": "nowrap" },
            },
            { title: "Kirjeldus", field: "description" },
          ]}
          data={getData}
          title=""
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 25, 10],
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            padding: "dense",
          }}
          onSearchChange={searchChange}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh",
              isFreeAction: true,
              onClick: refreshData,
            },
            viewMode ? {
              icon: "edit",
              tooltip: "Muuda",
              onClick: editRow,
            } : null,
            {
              icon: "delete",
              tooltip: "Kustuta",
              onClick: deleteRow,
            },
          ]}
          localization={{
            header: {
              actions: "",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} kokku {count}",
              labelRowsSelect: "rida",
            },
            toolbar: {
              searchPlaceholder: 'Otsi, nt söök või "kodu"',
            },
          }}
          components={{
            Toolbar: (props) => (
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    defaultChecked
                    color="default"
                    onChange={onViewModeChange}
                    checked={viewMode}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MTableToolbar {...props} />
                </Grid>
              </Grid>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
