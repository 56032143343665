import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Box, makeStyles } from "@material-ui/core";
import { useToken } from "../TokenContext";
import ClearIcon from "@material-ui/icons/Clear";
import { useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import throttle from "lodash/throttle";

const useStyles = makeStyles(() => ({
  containerGrid: {
    marginBottom: "0 !important",
  },
}));

const Expense = forwardRef((props, ref) => {
  const [id, setId] = useState(0);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [sum, setSum] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionOptions, setDescriptionOptions] = useState([]);
  const [formula, setFormula] = useState("");
  const [date, setDate] = useState(null);
  const [alertState, setAlertState] = useState({});
  const [descriptionClearer, setDescriptionClearer] = useState(false);
  const token = useToken();

  useImperativeHandle(ref, () => ({
    edit(id) {
      fetch(`api/financeapi/GetExpense?id=${id}`, {
        headers: {
          "API-TOKEN": token,
        },
      })
        .then((response) => response.json())
        .then((expense) => {
          setId(expense.id);
          setCategory(expense.category);
          setDate(expense.date);
          setDescription(expense.description);
          setSum(expense.sum);
        });
    },
  }));

  const handleSaveAndContinueExpense = () => {
    saveExpense(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    saveExpense(true);
  };

  const onCategoryChange = (event) => {
    setCategory(parseInt(event.target.value));
    sumInput.current.focus();
  };

  const clearFormState = () => {
    setId(0);
    setCategory("");
    setSum("");
    setFormula("");
    setDescription("");
    // setDescriptionClearer(!descriptionClearer);
    setDate(null);
  };

  const saveExpense = (clearForm) => {
    if (clearForm) {
      clearFormState();
    }

    fetch("api/financeapi/PostExpense", {
      method: "POST",
      headers: {
        "API-TOKEN": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
        Category: category,
        Description: description,
        Sum: sum === "" ? null : sum,
        SumExpression: formula,
        Date: date,
      }),
    }).then((response) => {
      if (response.status === 200) {
        setAlertState({
          text: id > 0 ? "Muudetud!" : "Lisatud!",
          severity: "success",
          isVisible: true,
        });
      } else {
        setAlertState({
          text: id > 0 ? "Viga muutmisel!" : "Viga lisamisel!",
          severity: "error",
          isVisible: true,
        });
      }

      setTimeout(() => {
        setAlertState({ ...alertState, isVisible: false });
      }, 3000);
    });
  };

  useEffect(() => {
    fetch("api/financeapi/GetExpenseCategories", {
      headers: {
        "API-TOKEN": token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        json.unshift({});
        setCategories(json);
      });
  }, [token]);

  const fetchDescriptions = useMemo(
    () =>
      throttle((searchText, callback) => {
        fetch("api/financeapi/SearchDescriptions", {
          method: "POST",
          headers: {
            "API-TOKEN": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchText: searchText,
            type: "Expense",
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            callback(json);
          });
      }, 1000),
    []
  );

  const searchDescriptions = (searchText) => {
    fetchDescriptions(searchText, (resultDescriptions) => {
      setDescriptionOptions(resultDescriptions);
    });
  };

  const sumInput = useRef();
  const classes = useStyles();

  return (
    <Box p={3}>
      <form onSubmit={handleFormSubmit}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing="1"
          className={classes.containerGrid}
        >
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Kategooria"
              value={category}
              onChange={onCategoryChange}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name} {category.symbols}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Summa"
              type="number"
              variant="outlined"
              fullWidth
              value={sum}
              inputRef={sumInput}
              onChange={(e) => setSum(parseFloat(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-required"
              label="Või valem"
              variant="outlined"
              fullWidth
              size="small"
              value={formula}
              onChange={(e) => setFormula(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              key={descriptionClearer}
              options={descriptionOptions}
              value={description}
              defaultValue={description}
              onInputChange={(event, newInputValue) => {
                setDescription(newInputValue);
                searchDescriptions(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kirjeldus"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Kuupäev"
                format="dd/MM/yyyy"
                mask="__/__/____"
                value={date}
                onChange={(date) => setDate(date)}
                fullWidth
                inputVariant="outlined"
                autoOk="true"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.containerGrid}
            >
              <ButtonGroup color="primary">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={clearFormState}
                >
                  Puhasta
                  <ClearIcon />
                </Button>
                {id <= 0 ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleSaveAndContinueExpense}
                  >
                    Lisa ja jätka
                  </Button>
                ) : null}

                <Button type="submit" variant="contained" color="primary">
                  {id > 0 ? "Salvesta" : "Lisa"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {alertState.isVisible ? (
              <Alert
                severity={alertState.severity}
                color={alertState.severity}
                variant="standard"
              >
                {alertState.text}
              </Alert>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

export default Expense;
